import React from "react";
import BlogLink from "./BlogLink";

const posts = [
  {
    id: 1,
    title: "Decoding Golf Course Tee Times: How They Truly Work",
    href: "/post/tee-times",
    description:
      "Dive into the world of golf and uncover the mechanics of golf course tee times. Learn how they work, why they're essential, and tips for getting the best slots.",
    date: "Mar 16, 2023",
    datetime: "2023-03-16",
    category: { title: "Sports", href: "#" },
  },
];

function BlogList() {
  return (
    <div className="py-12 sm:py-16 bg-green-700 w-full">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            From the blog
          </h2>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-100 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <BlogLink post={post} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogList;

import axios from "axios";
import sortArray from "./sortArray.ts";
import { usePage } from "../context/context";
import { useQuery } from "react-query";

class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ApiError";
  }
}

class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
  }
}

function removeDuplicates(array, property1, property2, property3) {
  const uniqueValues = new Set();
  const result: any[] = [];

  for (const obj of array) {
    const value1 = obj[property1];
    const value2 = obj[property2];
    const value3 = JSON.stringify(obj[property3]); // Convert the holes array to string
    const key = `${value1}-${value2}-${value3}`;

    if (!uniqueValues.has(key)) {
      uniqueValues.add(key);
      result.push(obj);
    }
  }

  return result;
}

const formatDate = (
  date: string | Date,
  type: "miClub" | "quick18" = "miClub"
) => {
  const d = new Date(date);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  return type === "quick18"
    ? `${d.getFullYear()}-${month}-${day}`
    : `${d.getFullYear()}-${month}-${day}`;
};

const useGetData = () => {
  const { date, city } = usePage();
  const queryKey = ["miClub", date, city];

  return useQuery(
    queryKey,
    async () => {
      try {
        const [miClubData, quick18Data] = await Promise.all([
          axios.get(
            `https://orca-app-wzksf.ondigitalocean.app/miclub/${formatDate(
              date,
              "miClub"
            )}/${city}`
          ),
          axios.get(
            `https://orca-app-wzksf.ondigitalocean.app/quick18/${formatDate(
              date,
              "quick18"
            )}/${city}`
          ),
        ]);

        const combinedData = [...miClubData.data, ...quick18Data.data];

        const uniqueData = removeDuplicates(
          combinedData,
          "time",
          "name",
          "holes"
        );

        return sortArray(uniqueData);
      } catch (error: any) {
        console.error(error);

        // Throw custom error types for specific handling
        if (error.isAxiosError) {
          const apiError = new ApiError("API Error");
          throw apiError;
        } else if (error.message === "Network Error") {
          const networkError = new NetworkError("Network Error");
          throw networkError;
        } else {
          throw error;
        }
      }
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: true,
      retry: 3, // Retry failed requests up to 3 times
      retryDelay: (retryAttempt) => retryAttempt * 1000, // Exponential backoff for retry delays
    }
  );
};

export default useGetData;

import React from "react";
import { PageProvider } from "../context/context";
import "./../styles/global.css";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import { Route, Router, Switch } from "./../util/router";
import NotFoundPage from "./404";
import FirebaseActionPage from "./firebase-action";
import IndexPage from "./index";
import Times from "./times";
import { createUserMetrics } from "metric-shack";
import Meta from "../components/Meta";
import BlogPost from "./BlogPost";
import BlogList from "./BlogList";

function App(props) {
  createUserMetrics("f2c882db-23a0-4723-a73c-7dcfe3740aa4");

  return (
    <QueryClientProvider>
      <AuthProvider>
        <PageProvider>
          <Router>
            <>
              {/* <BannerHole /> */}
              <Meta />

              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/teetimes" component={Times} />

                <Route path="/post/:postName" component={BlogPost} />

                <Route path="/blog" component={BlogList} />

                {/* <Route exact path="/about" component={AboutPage} /> */}

                {/* <Route exact path="/pricing" component={PricingPage} /> */}

                {/* <Route exact path="/auth/:type" component={AuthPage} /> */}

                {/* <Route exact path="/dashboard" component={DashboardPage} /> */}

                {/* <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                /> */}

                {/* <Route exact path="/legal/:section" component={LegalPage} /> */}

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </>
          </Router>
        </PageProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;

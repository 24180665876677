import React from "react";

function PageLoader(props) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      {!props.children && (
        <div className="relative p-4 w-64">
          <div className="flex mb-2 items-center justify-between">
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                Loading
              </span>
            </div>
            <div className="text-right">
              <span className="text-xs font-semibold inline-block text-teal-500">
                100%
              </span>
            </div>
          </div>
          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
            <div
              style={{ width: "100%" }}
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500 transition-all duration-1000 ease-in-out"
            ></div>
          </div>
        </div>
      )}

      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;

import { usePage } from "../context/context";

function useFilterData(data: any[]) {
  const { players, time, course, pagination } = usePage();
  console.log(course);

  if (!data) {
    return [];
  }

  return data
    .filter((item) => isAvailableForPlayers(item, players))
    .filter((item) => isTimeEligible(item, time))
    .filter((item) => isCourseEligible(item, course))
    .slice(0, pagination);
}

function isAvailableForPlayers(item: any, requiredPlayers: number): boolean {
  return item.availCount >= requiredPlayers;
}

function isTimeEligible(item: any, startTime: string): boolean {
  const itemTime = convertTimeStringToDate(item.time);
  const timeStart = convertTimeStringToDate(startTime);

  return itemTime >= timeStart;
}

function isCourseEligible(item: any, desiredCourse: string): boolean {
  return desiredCourse === "All" ? true : item.name === desiredCourse;
}

function convertTimeStringToDate(timeString: string): Date {
  return new Date("1970/01/01 " + timeString.replace(/(am|pm)/, " $1"));
}

export default useFilterData;

function sortArray(arr: any[]) {
  return arr.sort(function (a, b) {
    //if a.time is undefined or null skip
    if (!a.time) {
      return 1;
    }
    if (!b.time) {
      return -1;
    }

    const one: any = new Date("1970/01/01 " + a.time.replace(/(am|pm)/, " $1"));
    const two: any = new Date("1970/01/01 " + b.time.replace(/(am|pm)/, " $1"));
    return one - two;
  });
}

export default sortArray;

import { CloudArrowUpIcon, ServerIcon } from "@heroicons/react/20/solid";
import golf from "./golf.webp";

export default function SeoContent() {
  return (
    <section className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <header className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-300">
                Golf Slot Bookings in Australia
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Find and Book Premier Real-time Golf Tee Times and Courses in
                Melbourne, Sydney, Perth, and Brisbane
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-300">
                Discover how to book a golf slot or find golf courses near you
                in top Australian cities instantly. Our platform offers
                real-time tee time and golf course availability.
              </p>
            </div>
          </div>
        </header>

        <figure className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900"
            src={golf}
            alt="Scenic view of a public golf course near me in Australia with flagstick and hole."
          />
        </figure>

        <article className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-300 lg:max-w-lg">
              <p>
                Tee Time Wizard provides insights into the best public golf
                courses in Sydney and instant tee time reservations across
                Australia, perfect for last-minute golf near you.
              </p>
              <ul className="mt-8 space-y-8 text-gray-300">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-300"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-white">
                      Live Tee Time Availability in Australia.
                    </strong>{" "}
                    Browse and view golf slot and golf club availability at
                    premier courses, including popular spots for golf near you.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ServerIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-300"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-white">
                      Comprehensive Course Information.
                    </strong>{" "}
                    Get detailed insights and ratings on renowned golf courses
                    across Australia.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                We are not just another booking platform. Dive deep into the
                world of golf, explore top-rated public golf clubs and courses
                in Perth for tourists, and embark on a premium golfing
                experience.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-white">
                From Brisbane to Sydney: Your Seamless Golf Booking Journey in
                Australia Starts Here
              </h2>
              <p className="mt-6">
                Are you looking for last-minute tee times in Melbourne or
                planning a golf holiday in Brisbane? Begin with us today!
              </p>
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

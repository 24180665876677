import { createContext, useContext, useReducer } from "react";

const PageContext = createContext();

const PageDispatchContext = createContext();

export function PageProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PageContext.Provider value={state}>
      <PageDispatchContext.Provider value={dispatch}>
        {children}
      </PageDispatchContext.Provider>
    </PageContext.Provider>
  );
}

export function usePage() {
  return useContext(PageContext);
}

export function useDispatchPage() {
  return useContext(PageDispatchContext);
}

export const ACTIONS = {
  SET_DATE: "Set_Date",
  SET_TIME: "Set_Time",
  SET_PAGINATION: "Set_Pagination",
  SET_CITY: "Set_City",
  SET_PLAYERS: "Set_Players",
  SET_COURSE: "Set_Course",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_DATE:
      return { ...state, date: action.payload.split("T")[0] };
    case ACTIONS.SET_TIME:
      return { ...state, time: action.payload };
    case ACTIONS.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case ACTIONS.SET_CITY:
      return { ...state, city: action.payload };
    case ACTIONS.SET_PLAYERS:
      return { ...state, players: action.payload };
    case ACTIONS.SET_COURSE:
      return { ...state, course: action.payload };
    default:
      return state;
  }
}

const initialState = {
  date: new Date(),
  time: "5:00am",
  city: "",
  pagination: 100,
  players: 1,
  course: "All",
};

import {
  ClockIcon,
  ExclamationCircleIcon,
  FlagIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import useFilterData from "../util/useFilterData.ts";
import useGetData from "../util/useGetData.ts";

function groupByHour(data) {
  return data.reduce((acc, tee) => {
    let [hourStr, period] = tee.time.split(" ");
    let hour = parseInt(hourStr.split(":")[0], 10);
    let key = `${hour} ${period}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(tee);
    return acc;
  }, {});
}

const colors = [
  "bg-red-100",
  "bg-green-100",
  "bg-blue-100",
  "bg-yellow-100",
  "bg-indigo-100",
  "bg-purple-100",
  "bg-pink-100",
];
let colorMap = {};

function getColorForCourse(name) {
  if (!colorMap[name]) {
    const bg = colors[Object.keys(colorMap).length % colors.length];
    const text = bg.replace("-100", "-700").replace("bg-", "text-");
    const badge = bg.replace("-100", "-500").replace("bg-", "text-");
    colorMap[name] = {
      bg: bg,
      text: text,
      badge: badge,
    };
  }
  return colorMap[name];
}

export default function TeeTimeList() {
  const { data, isLoading, status } = useGetData();
  const tableData = useFilterData(data);

  const messageStyle =
    "flex flex-col items-center justify-center h-screen text-xl font-medium space-y-4";

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="border-t-4 border-blue-500 border-solid w-16 h-16 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (status !== "success") {
    return (
      <div className={messageStyle}>
        <ExclamationCircleIcon className="h-8 w-8 text-red-600" />
        Sorry, an error occurred. Please try again later.
      </div>
    );
  }

  if (tableData.length === 0) {
    return (
      <div className={messageStyle}>
        <InformationCircleIcon className="h-8 w-8 text-gray-600" />
        No data available for that date, try another.
      </div>
    );
  }

  const groupedData = groupByHour(tableData || []);

  return (
    <div className="space-y-6">
      {Object.entries(groupedData).map(([hourPeriod, teeTimes]) => (
        <div className="hour-group" key={hourPeriod}>
          <div className="pb-5 flex justify-center items-center">
            <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {hourPeriod}
            </h3>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
            {teeTimes.map((tee, index) => (
              <TeeTime tee={tee} key={tee.time + index} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

const TeeTime = ({ tee }) => {
  const courseColors = getColorForCourse(tee.name);

  const HolesBadge = ({ holes }) => {
    if (holes.includes(9) && holes.includes(18)) {
      return (
        <span className="inline-flex items-center text-gray-800 px-2 py-1 rounded text-sm">
          <FlagIcon className="h-5 w-5 mr-1" />9 or 18 holes
        </span>
      );
    }

    return (
      <>
        {holes.includes(9) && (
          <span className="inline-flex items-center text-gray-800 px-2 py-1 rounded text-sm">
            <FlagIcon className="h-5 w-5 mr-1" />9 holes
          </span>
        )}
        {holes.includes(18) && (
          <span className="inline-flex items-center text-gray-800 px-2 py-1 rounded text-sm">
            <FlagIcon className="h-5 w-5 mr-1" />
            18 holes
          </span>
        )}
      </>
    );
  };

  return (
    <div
      key={tee.time + tee.index}
      className="divide-y divide-gray-200 rounded-lg shadow-lg border"
    >
      <div className="flex flex-col space-y-2 p-2">
        <span
          className={`inline-flex items-center gap-x-1.5 text-md rounded-md ${courseColors.bg} px-2 py-1 font-medium ${courseColors.text}`}
        >
          <svg
            className={`h-3 w-3 ${courseColors.badge}`}
            fill="currentColor"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {tee.name}
        </span>
        <div className="grid grid-cols-2 gap-2">
          <span className="inline-flex items-center text-gray-800 px-2 py-1 rounded text-sm">
            <ClockIcon className="h-5 w-5 mr-1" />
            {tee.time}
          </span>
          <HolesBadge holes={tee.holes} />
        </div>
      </div>
      <div className="flex w-full">
        {tee.records.map((record, recordIndex) => (
          <div
            key={recordIndex}
            className={`flex-1 relative ${
              record === "Available"
                ? "inline-flex items-center justify-center text-gray-100 bg-gray-800"
                : "inline-flex items-center justify-center text-gray-800 bg-gray-100"
            } px-3 py-2 text-sm font-semibold focus:z-10`}
          >
            {record}
          </div>
        ))}
      </div>
      <div>
        <div className="-mt-px flex justify-center">
          <div className="flex w-full">
            <a
              target="blank"
              href={tee.url}
              className="relative inline-flex w-full items-center justify-center rounded-bl-lg rounded-br-lg py-4 text-sm font-semibold text-gray-50 bg-emerald-700 hover:bg-gray-700"
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import nsw from "../img/nsw.svg";
import qld from "../img/qld.svg";
import vic from "../img/vic.svg";
import wa from "../img/wa.svg";
import logo from "../components/golf.webp";

const states = [
  {
    name: "Sydney",
    description:
      "Discover premier golf courses near Sydney. Where the Harbour Bridge is just a backdrop to our golf adventures.",
    icon: nsw,
    background: "bg-red-500",
  },
  {
    name: "Brisbane",
    description:
      "Sunny skies and top-rated golf clubs near Brisbane. Don't let the weather distract you from the golfing experience.",
    icon: qld,
    background: "bg-green-500",
  },
  {
    name: "Melbourne",
    description:
      "Experience diverse weather and golf courses in Melbourne. Four seasons in one day, perfect for golfing.",
    icon: vic,
    background: "bg-blue-500",
  },
  {
    name: "Perth",
    description:
      "Explore remote and beautiful golf courses near Perth. Where even the golf balls are awed by the scenic views.",
    icon: wa,
    background: "bg-indigo-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NoCityState() {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 sm:pt-10 bg-gray-900">
      {/* Main background */}
      <div className="mx-auto max-w-3xl">
        <div className="overflow-hidden bg-gray-800 shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div>
              <div className="flex flex-col md:flex-row items-center">
                <span className="-m-1.5 p-1.5">
                  <span className="sr-only">Teetime Wizard</span>
                  <img
                    className="h-24 px-2 w-auto"
                    src={logo}
                    alt="Teetime Wizard Logo"
                  />
                </span>
                <h1 className="text-7xl tracking-wide font-bold tracking-tight text-gray-100 sm:text-6xl pacificoFont md:block text-center py-2">
                  Teetime Wizard
                </h1>
              </div>
              <ul className="mt-6 grid grid-cols-1 gap-6 border-b border-gray-700 border-t border-gray-700 py-6 sm:grid-cols-2">
                {/* Adjusted the border colors */}
                {states.map((state, idx) => (
                  <li key={idx} className="flow-root">
                    <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-gray-500 hover:bg-gray-800">
                      {/* Adjusted the focus and hover states */}
                      <div
                        className={classNames(
                          state.background,
                          "flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg"
                        )}
                      >
                        <img
                          src={state.icon}
                          className="h-14 w-14 text-white"
                          aria-hidden="true"
                          alt={`${state.name} Icon`}
                        />
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-200">
                          <a
                            href={`/teetimes/?city=${state.name}&date=${
                              new Date().toISOString().split("T")[0]
                            }`}
                            className="focus:outline-none"
                          >
                            <span
                              className="absolute text-4xl inset-0"
                              aria-hidden="true"
                            />
                            {state.name}
                            <span aria-hidden="true"> &rarr;</span>
                          </a>
                        </h3>
                        <p className="mt-1 text-xs text-gray-400">
                          {state.description}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              {/* More coming soon section */}
              <div className="mt-6 text-center py-6">
                <h3 className="text-sm font-semibold text-gray-200">
                  More states and golf courses coming soon!
                </h3>
                <p className="mt-2 text-sm text-gray-400">
                  Stay tuned for updates on golf clubs and courses near you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

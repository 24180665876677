import { FlagIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Navbar from "../components/Navbar";
import { useParams } from "../util/router";

function BlogPost() {
  const [postContent, setPostContent] = useState("");
  const { postName } = useParams();

  useEffect(() => {
    fetch(`/markdown-posts/${postName}.md`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((text) => setPostContent(text))
      .catch((error) => {
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        );
      });
  }, [postName]);

  const markdownComponents = {
    h1: ({ children }) => (
      <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mt-4 mb-4">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="text-2xl font-bold tracking-tight text-white mt-6 mb-4">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="text-xl font-semibold text-white mt-4 mb-2">{children}</h3>
    ),
    p: ({ children }) => (
      <p className="text-base mt-4 mb-4 text-white">{children}</p>
    ),
    a: ({ children, href }) => (
      <a className="text-indigo-400 hover:underline mb-2" href={href}>
        {children}
      </a>
    ),
    ul: ({ children }) => (
      <ul className="mt-4 mb-4 max-w-xl space-y-4 text-white">{children}</ul>
    ),
    li: ({ children }) => (
      <li className="flex gap-x-3">
        <FlagIcon
          className="mt-1 h-5 w-5 flex-none text-indigo-500"
          aria-hidden="true"
        />
        <span className="text-white">{children}</span>
      </li>
    ),
    blockquote: ({ children }) => (
      <figure className="mt-10 mb-10 border-l border-gray-600 pl-9">
        <blockquote className="font-semibold text-white mt-4 mb-4">
          {children}
        </blockquote>
      </figure>
    ),
    h4: ({ children }) => (
      <h4 className="text-lg font-semibold text-white mt-3 mb-2">{children}</h4>
    ),
  };

  return (
    <>
      <Navbar />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8">
        <div className="mx-auto max-w-3xl space-y-6">
          <ReactMarkdown components={markdownComponents}>
            {postContent}
          </ReactMarkdown>
        </div>
      </div>
    </>
  );
}

export default BlogPost;

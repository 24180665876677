import React from "react";
import NoCityState from "../components/NoCityState";
import SeoContent from "../components/seoContent";
import Meta from "../components/Meta";
import EmailCapture from "../components/emailCapture";
import BlogList from "./BlogList";

function IndexPage(props) {
  return (
    <div className="flex flex-col items-center justify-center bg-gray-900 sm:mt-10 py-5">
      {/* Render the Meta component */}
      <Meta
        title="Teetime Wizard - Find All Tee Times in Australia"
        description="Discover and book every tee time in Australia. Your one-stop destination for finding the best golf slots."
        image="/golf.webp"
      />

      <NoCityState />

      <EmailCapture />

      <BlogList />

      <SeoContent />
    </div>
  );
}

export default IndexPage;

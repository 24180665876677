import React, { useState, useEffect } from "react";
import { saveEmail } from "../util/db";

export default function EmailCapture() {
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState({ message: "", show: false });
  const [emailSaved, setEmailSaved] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email) {
      try {
        await saveEmail({ email });
        setEmailSaved(true);
        setToast({ message: "Email saved successfully!", show: true });
      } catch (error) {
        console.error("Error saving email:", error);
        setToast({ message: "Failed to save email.", show: true });
      }
      setEmail("");
    } else {
      setToast({ message: "Please enter an email.", show: true });
    }
  };

  useEffect(() => {
    if (toast.show) {
      const timer = setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toast]);

  return (
    <div className="bg-indigo-700 py-16 sm:py-24 lg:py-32 w-full mt-10">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
          <h2 className="inline sm:block lg:inline xl:block">
            Golf Deals You Can't Miss!
          </h2>{" "}
          <p className="inline sm:block lg:inline xl:block">
            Access top golf brand deals and exclusives. Your support keeps us
            swinging!
          </p>
        </div>
        {!emailSaved ? (
          <form
            className="w-full max-w-md lg:col-span-5 lg:pt-2"
            onSubmit={handleSubmit}
          >
            <div className="flex gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-white/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Tee Off
              </button>
            </div>
          </form>
        ) : (
          <div className="w-full max-w-md lg:col-span-5 lg:pt-2 text-center">
            <p className="text-lg font-semibold text-white">
              Thank you! You're now subscribed to exclusive deals and offers.
            </p>
          </div>
        )}
      </div>

      {toast.show && (
        <div className="fixed bottom-5 right-5 z-50 rounded-md bg-white px-4 py-2 shadow-lg">
          <p className="text-sm text-gray-700">{toast.message}</p>
        </div>
      )}
    </div>
  );
}
